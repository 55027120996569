@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400&display=swap");

:root {
	--default-box-shadow: 0px 0px 4px 2px hsla(0, 0%, 0%, 0.3);
	--default-box-shadow-small: 0px 0px 2px 2px hsla(0, 0%, 0%, 0.1);
	--default-inner-shadow: inset 0 0 0 2px hsl(192, 100%, 30%);
	--default-inner-shadow-hover: inset 0 0 0 2px hsl(192, 90%, 40%);
	--default-slide-transition-fn: cubic-bezier(0.09, 0.7, 0.55, 1);
}

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

html {
	background-color: black;
}

html,
body {
	width: 100vw;
	max-width: 100%;
	min-height: 100vh;
	height: 100%;
	color: white;
	display: flex;
	justify-content: center;
	font-family: "Roboto", sans-serif;
	overflow-x: hidden;
}

b {
	text-align: left;
}
p {
	text-align: left !important;
	font-size: 21px;
	line-height: 1.4;
}

@media (max-width: 767px) {
	p {
		margin-right: 0;
		font-size: 19px;
	}
}
#icon * {
	fill: white;
}
h1 {
	font-size: 75px;
	font-family: "Fixedsys Excelsior 3.01" !important;
	letter-spacing: 10px;
	font-weight: 300;
	text-align: center;
}

.heading-text {
	height: 50px;
	width: auto;
}

.heading-text-big {
	height: 100px;
	width: auto;
}

@media only screen and (max-width: 1000px) {
	h1 {
		font-size: 60px;
	}
	.heading-text {
		height: 40px;
		width: auto;
	}

	.heading-text-big {
		height: 80px;
		width: auto;
	}
}

@media only screen and (max-width: 800px) {
	h1 {
		font-size: 50px;
	}
	.heading-text {
		height: 25px;
		width: auto;
	}

	.heading-text-big {
		height: 50px;
		width: auto;
	}
}

@media only screen and (max-width: 420px) {
	h1 {
		font-size: 40px;
	}

	.heading-text {
		height: 20px;
		width: auto;
	}

	.heading-text-big {
		height: 40px;
		width: auto;
	}
}

h2 {
	font-size: 2rem;
}
h4 {
	color: var(--gray);
	line-height: 2em;
}

.gallery-row > img {
	width: 25%;
	margin: 5px;
	border-radius: 10px;
	cursor: pointer;
}

.hide-overflown-text-nowrap {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.hide-overflown-text-wrap {
	overflow: hidden;
	text-overflow: ellipsis;
}

hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

::-webkit-scrollbar-track {
	background-color: black;
	border-radius: 10px;
}

.btn-plain {
	outline: none;
	border: none;
	color: inherit;
	font: inherit;
	background-color: unset;
}

.bg-image {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.vertical-line {
	content: "";
	position: relative;
	z-index: 1;
	top: 0;
	bottom: 0;
	left: 50%;
	border-left: 2px solid white;
	transform: translate(-50%);
}

.flex-stretch {
	display: flex;
	align-items: stretch;
}
.flex-baseline {
	display: flex;
	align-items: baseline;
}
.flex-space-between {
	display: flex;
	justify-content: space-between;
}
.flex-space-around {
	display: flex;
	justify-content: space-around;
}
.flex-space-evenly {
	display: flex;
	justify-content: space-evenly;
}
.flex-allow-wrap {
	flex-wrap: wrap;
}

.figure-image {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.image-fixed-top {
	background-size: contain;
	background-attachment: local;
	background-repeat: no-repeat;
	background-position: top;
}

.btn-red {
	background-color: #ff0000;
}

.btn-green {
	background-color: #00976a;
}

.btn-gray {
	background-color: #303030;
}

.required:after {
	content: " *";
	color: rgb(209, 6, 6);
}

::-webkit-scrollbar {
	width: 5px;
	height: 10px;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: rgb(80, 80, 80);
}

:disabled {
	opacity: 0.8;
	cursor: not-allowed;
}

.font-size-12 {
	font-size: 12px;
}
.font-size-14 {
	font-size: 14px;
}
.font-size-16 {
	font-size: 16px;
}
.font-size-18 {
	font-size: 18px;
}
.font-size-21 {
	font-size: 21px;
}

/*
TODO:
Decide whether to and Do:
(1) Remove these flex classes,
    replacing elsewhere in code appropriately with <HyperDiv />
    (have to create "pyramids", for example when there is something like
    <BaseContainer /> from styled components, would have to put <HyperDiv />
    as a child of that,
(2) Leave these here,
(3) Use the appropriate flex properties inside of styled.div` ... `,
(4) Allow both options [1] and [3].
*/
.flex-row {
	display: flex;
	flex-direction: row;
}
.flex-col {
	display: flex;
	flex-direction: column;
}
.center-x {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}
.center-y {
	display: flex;
	align-items: center;
}
.center-x-y {
	display: flex;
	justify-content: center;
	align-items: center;
}
.center-y-start {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.center-y-end {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.flex-start-x {
	display: flex;
	justify-content: flex-start;
}
.flex-start-y {
	display: flex;
	align-items: flex-start;
}
.flex-end-x {
	display: flex;
	justify-content: flex-end;
}
.flex-end-y {
	display: flex;
	align-items: flex-end;
}

a {
	text-decoration: none;
	color: white;
	font-weight: bold;
}
.text-align-end {
	text-align: end;
}

.float-right {
	float: right;
}

/* fill container */
.fill-cter {
	width: 100%;
	height: 100%;
}

.absolute {
	position: absolute;
}
.absolute-0 {
	position: absolute;
	top: 0;
	left: 0;
}
.relative {
	position: relative;
}

.non-draggable {
	user-select: none;
	-webkit-user-drag: none;

	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

.half-transparent {
	opacity: 0.5;
}

table {
	font-family: "Roboto";
	border-collapse: collapse;
	width: 100%;
	border: 1px solid var(--classic-gray) !important;
}

.color-nth-row:nth-child(even) {
	background-color: var(--light-light-gray);
}

.blur-bg-overlay {
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 50;
	background: #7a7a7a70;
	width: 100vw;
	height: 100vh;
}

.selected-row:hover {
	background-color: var(--light-gray);
}

.selected-row:hover {
	background-color: var(--light-blue);
}

.selected-row > td {
	font-weight: 400 !important;
}

.fadeIn {
	animation: fadeIn 1s linear forwards;
}

.fadeOut {
	animation: fadeOut 1s linear forwards;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.element-hover {
	transition: 0.3s;
}
.element-hover:hover {
	opacity: 0.8;
	cursor: pointer !important;
}
td,
th {
	border: 1px solid var(--classic-gray);
	text-align: left;
	padding: 4px;
	padding-left: 0.5em;
	padding-right: 0.5em;
}
td .remove-cell-padding,
th .remove-cell-padding {
	padding-left: 0;
	padding-right: 0;
}

td {
	height: 18px;
	font-weight: 340;
	font-size: 12px;
}
th {
	height: 50px;
	font-size: 13px;
}

.pointer {
	cursor: pointer;
}
.cursor-blocked {
	cursor: not-allowed;
}

.flip-horizontal {
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.font-weight-bold {
	font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

/* For images */
.image {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 80%;
}
.logo {
	width: auto;
	height: 50px;
}
.imageHover {
	height: 80%;
}
.imageHover:hover {
	height: 80%;
	filter: invert(15%) sepia(26%) saturate(882%) hue-rotate(203deg) brightness(96%) contrast(91%);
}

.fixed-column-width {
	table-layout: fixed;
}
/* Dev */
.test {
	background-color: red;
}
.test-blue {
	background-color: blue;
}

/* For CSS drop down icons */
.arrow {
	border: solid black;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 2px;
}
.right {
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
}

.hover-highlight:hover {
	background-color: var(--gray-ec);
}

.left {
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
}
.up {
	transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
}
.down {
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}

.w-100-children > div {
	width: 100%;
}

.disable-pointer-event {
	pointer-events: none;
}

.DayPicker-Day {
	padding: 1em 1.5em !important;
}

@media (max-width: 520px) {
	.DayPicker-Day {
		padding: 1em 1em !important;
	}
}

@media (max-width: 400px) {
	.DayPicker-Day {
		padding: 1em 0.5em !important;
	}
}
.DayPicker-Day--selected {
	background-color: var(--main-red) !important;
	border-radius: 20px !important;
}

div.DayPicker:not(.DayPicker--interactionDisabled)
	.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
	background-color: rgba(255, 255, 255, 0) !important;
}

.time > input {
	height: 38px;
	padding: 0 16px;
	background-color: var(--black);
	outline-color: var(--white);
	border-radius: 10px;
	border: 2px solid var(--gray-c2);

	outline: 0ch !important;
	color: inherit;
	font-family: inherit;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.6;
	text-transform: none;
	word-wrap: normal;

	transition: 0.2s var(--default-slide-transition);
	transition-property: border-color;
}

.rc-time-picker-panel-inner * {
	background: black !important;
	background-color: black !important;
}

.rc-time-picker-panel-inner {
	border: 1px solid var(--gray-c2) !important;
	outline: 1px solid white !important;
	background: black !important;
	box-shadow: none !important;
	top: 35px !important;
	color: white !important;
}

.rc-time-picker-panel-input-wrap {
	display: none;
}
