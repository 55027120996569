:root {
	/* color labels */
	--aa-color: #51b39c;
	--info: #009efe;
	--warning: #fecd00;
	--fail: #f72646;
	--success: #27ad5f;

	--EASY: #178c15;
	--MEDIUM: #ee751b;
	--HARD: #c02b29;

	--main-red: #fe0200;
	--main-red-active: #cc6565;
	--main-black: #000000;

	/* pure colors */
	--indigo: #2c2b44;
	--white: #fff;
	--black: #000000;
	--blackish-gray: #1d1f24;
	--blue: #267ce7; /* button default */
	--dark-blue: #2069c4; /* button hover */
	--light-blue: #72baef;
	--midnight-blue: #091e38;
	--gray: #434343;
	--muddy-puddle-gray: #616266;
	--gray-81: #818181;
	--gray-ec: #ececec;
	--gray-c2: #c2c2c2;
	--gray-f0: #f0f0f0;
	--gray-66: #666666;
	--gray-dc: #dcdcdc;
	--classic-gray: #c4c4c4;
	--light-gray: #ced4da;
	--lighter-gray: #ececec;
	--light-light-gray: #f9fafc;
	--some-other-kind-of-gray: #98a1a4;
}
